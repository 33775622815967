<template>
  <div id="about">
    <div class="about_bg">
    </div>
    <div class="about">
      <div class="about_left">
      </div>

      <div class="about_right">
        <span style="color: #BABABA;font-size: 25px">About as</span>
        <h3>关于我们</h3>
        <div style="float:left;width: 50px;background: #177DF3;height: 5px;"></div>
        <br/>
        <p style="font-size: 14px;line-height: 27px">
          上海龙艺贯彻党和政府对于新就业形态支持政策，努力实现龙艺人力资源服务价值，在“保就业”、“稳就业”方面发挥积极作用。经过深入地市场调研，研判市场环境、产业链结构与市场发展空间，龙艺以人力资源供给与需求双向分析为主线，挖掘灵活用工平台发展机会。
        </p>
        <p style="font-size: 14px;line-height: 27px">
          上海龙艺企业管理集团借助移动互联网信息技术搭建新业态灵活用工平台，以订单为纽带，在用工企业和劳动者之间搭建起信息沟通的桥梁，致力于打造一个涵盖用工、招聘、培训、咨询及保障于一体的综合性服务平台。一方面，可有效缓解一直以来困扰企业的“用工难”
          与“成本高”问题，为阶段性缺工企业提供供需对接服务。另一方面，龙艺积极搭建线上线下渠道，打造“百艺云创”与“容易快聘”平台为主阵地，广泛发布全职、短工、零工、兼职及自由职业等各类需求信息，支持劳动者灵活就业，解决广大劳动者“就业难”困局。
        </p>
      </div>
      </div>
  </div>

</template>
<script>
import banner from "../assets/home/banner.png";
export default {
  data() {
    return {
      banner: banner
    }
  },
  created(){
    this.$emit('changeTab',4)
  }
}
</script>
<style scoped>
#about{
  height: 1200px;
  width: 100%;
  background: #eceff2;
  position: relative;
  padding-top: 80px;
}
.about_bg{
  width: 100%;
  height: 415px;
  background: url("../assets/home/vadio.png");
}
.about{
  width: 950px;
  height: 500px;
  background: white;
  margin:10vh auto;
  padding: 20px;
}
.about_left{
  width: 50%;
  height: 100%;
  float: left;
  margin-right: 30px;
  background: url("../assets/home/jrwm.png");
  background-size: 100% 100%;
}
.about_right{
  text-align:left;
}
</style>